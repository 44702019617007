
























































































































































































































import SelectableDaysOfWeek from "@/components/SelectableDaysOfWeek.vue";
import { Component, Provide, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import TheoryList from "@/views/Theory/TheoryList.vue";
import TheoryCreateCard from "@/views/Theory/TheoryCreateCard.vue";
import TheoryEditCard from "@/views/Theory/TheoryEditCard.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import { namespace } from "vuex-class";
import CopyButton from "@/components/Button/CopyButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import EditButton from "@/components/Button/EditButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import Table from "@/components/Table.vue";
import TheoryDetails from "@/views/Theory/TheoryDetails.vue";
import { LESSON } from "@/constants/TheoryOptions";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Students from "@/views/Theory/Students.vue";
import Datepicker from "@/components/Datepicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import { IInstructor } from "@/interfaces/IInstructor";
import { ITheoryCourse } from "@/interfaces/ITheoryCourse";
import { ITheoryClass } from "@/interfaces/ITheoryClass";
import TheorySessions from "@/views/Theory/TheorySessions.vue";
import moment from "moment";
import { mixins } from "vue-class-component";
import TheoryMixin from "@/mixins/TheoryMixin";
import { getFile } from "@/utils/File";
import TheorySignature from "@/views/Theory/TheorySignature.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import QrcodeButton from "@/components/Button/QrcodeButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import ModalMixin from "@/mixins/ModalMixin";
import CheckWorkingHoursModal from "@/components/Modal/CheckWorkingHoursModal.vue";
import CheckOverlappingAppointmentsModal from "@/components/Modal/CheckOverlappingAppointmentsModal.vue";
import { ICheckOverlappingAppointmentsOptions } from "@/interfaces/ICheckOverlappingAppointmentsOptions";
import CheckWorkingHoursAndOverlappingAppointmentMixin from "@/mixins/CheckWorkingHoursAndOverlappingAppointmentMixin.ts";
import PdfView from "@/views/AutomaticPaymentsOverview/PdfView.vue";
import TheoryStudentMatchModal from "@/views/Theory/TheoryStudentMatchModal.vue";
import TheorySignatureModal from "@/views/Theory/TheorySignatureModal.vue";
import TheorySignaturePdfOverwriteModal from "@/views/Theory/TheorySignaturePdfOverwriteModal.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import FuncMixins from "@/mixins/FuncMixins";
import ActionButton from "@/components/Button/ActionButton.vue";
import { boolenToEnum, OverlappingCheckEnum } from "@/enums/OverlappingCheckEnum";
import { ICheckWorkingHoursRepeatingEvent } from "@/interfaces/ICheckOverlappingAppointmentsRepeatingEventOptions";
import { DAILY } from "@/constants/RecurringAppointments";
import { ITheoryCourseDateFilter } from "@/interfaces/ITheoryCourseDateFilter";
import TheoryDateFilter from "@/views/Theory/TheoryDateFilter.vue";
import StudentListRequestMixin from "@/mixins/Request/StudentListRequestMixin";
import { dateAndTimeToDateTime } from "@/utils/DateTime";
import BookButton from "@/components/Button/BookButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import TemplateEmailForm from "@/components/TemplateEmailForm.vue";
import { formatInstructorName } from "@/utils/NameUtil";
import TheoryPrintActionModal from "@/views/Theory/modals/TheoryPrintActionModal.vue";
import WalletButton from "@/components/Button/WalletButton.vue";
import TheoryModuleBookModal from "@/views/Theory/modals/TheoryModuleBookModal.vue";
import RefreshButton from "@/components/Button/RefreshButton.vue";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";

const TheoryLessonModule = namespace("theory-lesson");
const TheoryLessonFilterModule = namespace("theory-lesson/localFilter");
const TheoryCourseModule = namespace("theory-course");
const BranchModule = namespace("branch");
const TheoryClassModule = namespace("theory-class");
const InstructorModule = namespace("instructor");
const StudentModule = namespace("student");
const KnowledgeAreaModule = namespace("knowledge-area");
const CalendarModule = namespace("calendar");

const theoryClassResource = "theory-class";
const branchResource = "branches";
const theoryLessonResource = "theory-lesson";
const theoryCourseResource = "theory-courses";

@Component({
  methods: { dateAndTimeToDateTime },
  components: {
    RefreshButton,
    TheoryPrintActionModal,
    TheoryDateFilter,
    ActionButton,
    FilterAndSearch,
    TheorySignatureModal,
    PdfView,
    DeleteModal,
    RemoveButton,
    CheckOverlappingAppointmentsModal,
    CheckWorkingHoursModal,
    QrcodeButton,
    FscSimpleCard,
    TheorySessions,
    TimePicker,
    Datepicker,
    Students,
    SaveButton,
    AbortButton,
    TheoryDetails,
    Table,
    EditButton,
    VerticalDivider,
    CopyButton,
    CreateButton,
    Actions,
    TheoryCreateCard,
    TheoryEditCard,
    TheoryList,
    FscPageHeader,
    SelectableDaysOfWeek,
    PrintButton,
    TheorySignature,
    TheoryStudentMatchModal,
    TheorySignaturePdfOverwriteModal,
    BookButton,
    MailButton,
    TemplateEmailForm,
    WalletButton,
    TheoryModuleBookModal,
  },
})
export default class Theory extends mixins(TheoryMixin, ModalMixin, CheckWorkingHoursAndOverlappingAppointmentMixin, FuncMixins, StudentListRequestMixin) {
  public name = "Theory";

  @TheoryLessonFilterModule.State("filter")
  public theoryLocalFilter: any;

  @TheoryLessonFilterModule.Mutation("SET_LOCAL_FILTER")
  public setTheoryLocalFilter!: (payload: Record<any, any>) => void;

  // Theory module
  @TheoryLessonModule.Getter("getIsLoading")
  protected theoryLessonsIsLoading!: any;

  @TheoryLessonModule.Getter("getBooked")
  protected getTheoryLessonBookedStatus!: any;

  @TheoryLessonModule.Mutation("SET_BOOKED")
  protected setTheoryLessonBookedStatus!: any;

  @TheoryLessonModule.Action("filter")
  protected theoryLessonFilter: any;

  @TheoryLessonModule.Action("create")
  protected theoryLessonCreate: any;

  @TheoryLessonModule.Action("del")
  protected theoryLessonRemove: any;

  @TheoryLessonModule.Action("findByCourseId")
  protected theoryLessonByCourseId: any;

  @TheoryLessonModule.Action("book")
  protected theoryLessonBook: any;

  @TheoryLessonModule.Getter("getCourseDataList")
  protected theoryLessonCourses!: any;

  @TheoryLessonModule.Getter("getSuccess")
  protected theoryLessonSuccess!: any;

  @TheoryLessonModule.Action("findOne")
  protected theoryLessonFindOne: any;

  @TheoryLessonModule.Getter("getDataItemLoading")
  protected theoryLessonLoading: any;

  @TheoryLessonModule.Getter("getDataItem")
  protected theoryLesson!: any;

  @TheoryLessonModule.Action("update")
  protected updateTheoryLesson: any;

  @TheoryLessonModule.Getter("getDeleted")
  protected theoryLessonIsDeleted!: any;

  @TheoryLessonModule.Action("findMatchedById")
  protected findMatchByTheoryLessonAndStudentId: any;

  @TheoryLessonModule.Getter("getMatchDataItem")
  protected matchDataItem: any;

  // Branch module
  @BranchModule.Action("findAll")
  protected branchFindAll: any;

  @BranchModule.Getter("getDataList")
  protected branches!: any;

  // Theory class module
  @TheoryClassModule.Action("findAll")
  protected theoryClassFindAll: any;

  @TheoryClassModule.Getter("getDataList")
  protected theoryClasses!: any;

  // Course
  @TheoryCourseModule.Action("findCoursesAndTheoryLessons")
  protected findCoursesAndTheoryLessons!: (filter: ITheoryCourseDateFilter) => Promise<void>;

  @TheoryCourseModule.Getter("getCoursesAndTheoryLessons")
  protected getCoursesAndTheoryLessons!: any;

  @TheoryCourseModule.Action("create")
  protected theoryCourseCreate: any;

  @TheoryCourseModule.Getter("getSuccess")
  protected theoryCourseSuccess!: any;

  @TheoryCourseModule.Action("findOne")
  protected theoryCourseFindOne: any;

  @TheoryCourseModule.Getter("getDataItem")
  protected theoryCourse!: any;

  @TheoryCourseModule.Getter("getDataItemLoading")
  protected theoryCourseLoading!: any;

  @TheoryCourseModule.Action("update")
  protected updateTheoryCourse: any;

  @TheoryCourseModule.Action("del")
  protected theoryCourseDelete: any;

  @TheoryCourseModule.Getter("getIsLoading")
  protected theoryIsLoading!: any;

  @TheoryCourseModule.Getter("getDeleted")
  protected theoryCourseIsDeleted!: any;

  // Instructor
  @InstructorModule.Action("filter")
  protected instructorFilter: any;

  @InstructorModule.Getter("getDataList")
  protected instructors!: any;

  // Knowledge area
  @KnowledgeAreaModule.Action("findAllBasicEducations")
  public knowledgeAreaFindAll: any;

  @KnowledgeAreaModule.Getter("getDataList")
  public knowledgeAreas!: any;

  @KnowledgeAreaModule.Action("findAllFurtherEducations")
  public findAllFurtherEducations: any;

  @KnowledgeAreaModule.Getter("getFutherEducationList")
  public furtherEducationknowledgeAreas!: any;

  // Calendar
  @CalendarModule.Action("checkWorkingHours")
  public checkWorkingHours!: (options: ICheckOverlappingAppointmentsOptions) => Promise<void>;

  @CalendarModule.Action("checkOverlappingAppointments")
  public checkOverlappingAppointments!: (options: ICheckOverlappingAppointmentsOptions) => Promise<void>;

  @CalendarModule.Getter("getCheckWorkingHours")
  public getCheckWorkingHours!: boolean | null;

  @CalendarModule.Getter("getCheckOverlappingAppointments")
  public getCheckOverlappingAppointments!: boolean | null;

  // Calendar Repeat Event Getters
  @CalendarModule.Getter("getCheckWorkingHoursRepeatingEvent")
  public getCheckWorkingHoursRepeatingEvent!: boolean | null;

  @CalendarModule.Getter("getCheckOverlappingAppointmentsRepeatingEvent")
  public getCheckOverlappingAppointmentsRepeatingEvent!: boolean | null;

  public filterTheory = "";
  public radioSelected = "bkf-certificate";
  public printDocumentModal = "printDocumentModal";
  public documentDate: any | null = "";
  public showPrintModal = false;
  public showModuleBookingModal = false;

  public onSearchTheory(value: string): void {
    this.debouncer(() => {
      this.filterTheory = value;
    });
  }

  protected studentFilter = "";
  protected selectedRow: any = null;
  protected selectedTheoryLesson: any = null;
  protected oldSelectedTheoryLesson: any = null;
  protected visibleTheoryList = true;
  protected visibleCreateCard = false;
  protected visibleEditCard = false;
  protected visibleSessionTable = false;
  protected visibleStudentsTable = false;
  protected visiblePdfView = false;
  protected visibleAttendees = false;
  protected theory: ITheoryCourse | null = null;
  protected theoryLessons: any = [];
  protected theoryLessonStudents: any = [];
  protected abortAttendeesUuid = 0;
  protected courseId = 0;
  protected theoryDocumentId: any = null;
  protected pdfFile = "";
  protected visibleEmailForm = false;
  protected refreshCounter = 0;

  private fromDate = moment().subtract("2", "week").format("YYYY-MM-DD");
  private toDate = moment().add("2", "week").format("YYYY-MM-DD");

  protected modalTable: "theory-course" | "theory-session" | null = null;
  protected submitTheoryCourseData: {
    data: null;
    theory: null;
  } | null = null;
  protected submitTheorySessionData = null;
  protected studentToMatch: any = null;
  protected signatureSignPopoverModal = "signatureSignPopoverModal";
  protected selectedStudents: any = [];

  protected get isTheoryLessonActual() {
    return this.hasTheoryLessonGetActual(this.selectedTheoryLesson);
  }

  protected get isTheoryLessonBooked() {
    return this.selectedTheoryLesson?.booked || this.getTheoryLessonBookedStatus;
  }

  protected documentTypeModalVisible = false;

  protected get initialDate() {
    return moment().format("YYYY-MM-DD");
  }

  protected showDocumentSelectionForm() {
    this.showPrintModal = true;
  }

  protected onPrintModalClose() {
    this.showPrintModal = false;
  }

  protected onCreate(): void {
    if (this.visibleEditCard) {
      return;
    }
    this.visibleCreateCard = true;
  }

  protected onEdit(): void {
    if (this.visibleCreateCard) {
      return;
    }

    if (this.selectedTheoryIsCourse) {
      this.visibleEditCard = true;
    } else if (this.selectedTheoryIsLesson) {
      this.$toasted.info("Theory lesson can be editable only from middle table.");
    }
  }

  protected async onSubmitTheory(data: any, theory: any): Promise<void> {
    this.modalTable = "theory-course";
    this.submitTheoryCourseData = {
      data,
      theory,
    };
    if (theory.id === LESSON) {
      if (data.instructor) {
        const endTime = this.calcEndTime(data.time, data.duration);

        const checkData: ICheckOverlappingAppointmentsOptions = {
          instructorId: data.instructor.id,
          data: {
            startTime: this.toDateTime(data.date, data.time),
            endTime: this.toDateTime(data.date, endTime),
            eventId: null,
            eventTypeId: LESSON,
          },
        };

        // Check if the instructor is available
        if (!this.checkHoursModalConfirmed) {
          const checkWorkingHours = await this.checkInstructorWorkingHoursFn(checkData);
          if (checkWorkingHours === 1) {
            return;
          } else {
            this.checkHoursModalConfirmed = true;
          }
        }

        // Check if the instructor has another lesson at the same time
        if (!this.checkOverlappingAppointmentModalConfirmed) {
          const checkOverlapping = await this.checkInstructorOverlappingAppointmentsFn(checkData);
          if (checkOverlapping === 1) {
            return;
          } else {
            this.checkOverlappingAppointmentModalConfirmed = true;
          }
        }
      }

      await this.theoryLessonCreate({
        resource: theoryLessonResource,
        data: data,
      });

      this.resetConfirmationState();

      if (_.isBoolean(this.theoryLessonSuccess) && this.theoryLessonSuccess) {
        this.hideCardAndFetchCoursesAndTheoryLessons();
      }
    } else {
      if (data.instructor) {
        const checkData: ICheckWorkingHoursRepeatingEvent = {
          instructorId: data.instructor.id,
          data: {
            startDate: data.startDate,
            endDate: data.endDate,
            timeFrame: data.timeFrame,
            daysOfWeek: data.daysOfWeek,
            recurringType: DAILY,
          },
        };

        if (!this.checkHoursModalConfirmed) {
          const checkWorkingHours = await this.checkInstructorWorkingHoursRepeatEventFn(checkData);
          if (checkWorkingHours === 1) {
            return;
          } else {
            this.checkHoursModalConfirmed = true;
          }
        }

        if (!this.checkOverlappingAppointmentModalConfirmed) {
          const checkOverlapping = await this.checkInstructorOverlappingAppointmentsRepeatEventFn(checkData);
          if (checkOverlapping === 1) {
            return;
          } else {
            this.checkOverlappingAppointmentModalConfirmed = true;
          }
        }
      }

      this.submitTheoryCourseData = null;
      this.resetConfirmationState();

      await this.theoryCourseCreate({
        resource: theoryCourseResource,
        data: data,
      });

      if (_.isBoolean(this.theoryCourseSuccess) && this.theoryCourseSuccess) {
        this.hideCardAndFetchCoursesAndTheoryLessons();
      }
    }
  }

  protected async onSubmitEditTheory(data: any, theoryId: number): Promise<void> {
    await this.updateTheoryCourse({
      id: theoryId,
      data,
      resource: theoryCourseResource,
    });
    if (this.theoryCourseSuccess) {
      this.hideEditCardAndFetchCoursesAndTheoryLessons();
      this.closeSessionsTable();
    }
  }

  protected hideEditCardAndFetchCoursesAndTheoryLessons(): void {
    this.onAbortEdit();
    this.fetchCoursesAndTheoryLessons();
  }

  protected hideCardAndFetchCoursesAndTheoryLessons(): void {
    this.onAbortTheory();
    this.fetchCoursesAndTheoryLessons();
  }

  protected onAbortTheory(): void {
    this.visibleCreateCard = false;
  }

  protected onAbortEdit(): void {
    this.visibleEditCard = false;
  }

  protected async onBookTheoryCourse(id: any) {
    if (!this.selectedTheoryLesson) return;
    await this.theoryLessonBook(this.selectedTheoryLesson.id);
    await this.fetchTheoryLessons(this.selectedRow);
  }

  public mounted(): void {
    if (this.theoryLocalFilter instanceof Object) {
      const { toDate, fromDate } = this.theoryLocalFilter;

      if (toDate) {
        this.toDate = toDate;
      }

      if (fromDate) {
        this.fromDate = fromDate;
      }
    }

    this.fetchCoursesAndTheoryLessons();

    this.branchFindAll({
      resource: branchResource,
      params: { archived: false },
    });

    this.theoryClassFindAll({
      resource: theoryClassResource,
    });

    this.instructorFilter({ resource: "instructor", filter: { archived: false, ...DefaultInstructorSorting } });

    this.knowledgeAreaFindAll();
    this.findAllFurtherEducations();

    this.$watch(
      () => [this.selectedTheoryLesson, this.abortAttendeesUuid],
      () => {
        this.theoryLessonStudents = this.theoryLessonStudentsDto(this.selectedTheoryLesson?.studentTheoryLessons);
      }
    );

    this.$watch(
      () => [this.fromDate, this.toDate],
      () => {
        this.fetchCoursesAndTheoryLessons();
        this.setTheoryLocalFilter({
          fromDate: this.fromDate,
          toDate: this.toDate,
        });
      }
    );
    this.$root.$on("form-email-close", () => {
      this.onEmailFormClose();
    });
  }

  protected fetchCoursesAndTheoryLessons(): void {
    this.findCoursesAndTheoryLessons({
      fromDate: this.fromDate,
      toDate: this.toDate,
    });
  }

  protected onCreateTheorySession(): void {
    this.theoryLessons.unshift({
      date: "",
      _showDetails: true,
      knowledgeAreas: [],
      studentTheoryLessons: [],
      time: "",
      endTime: "",
      isNew: true,
    });
  }

  protected get coursesAndTheoryLessons(): Array<any> {
    if (this.filterTheory) {
      return this.getCoursesAndTheoryLessons.filter((courseAndTheoryLesson: any) => {
        return courseAndTheoryLesson.name.toLowerCase().indexOf(this.filterTheory.toLowerCase()) > -1;
      });
    }
    return this.getCoursesAndTheoryLessons;
  }

  protected async onClickRow(item: any): Promise<void> {
    this.showPrintModal = false;
    this.showModuleBookingModal = false;
    this.selectedRow = item;
    await this.fetchTheoryLessons(item);
    this.visibleEmailForm = false;
    this.visibleSessionTable = true;
    this.closePdfViewerOpenStudents();
    this.resetSelectedTheoryLesson();
  }

  protected onCreateAttendees(): void {
    this.visibleTheoryList = false;
    this.visibleStudentsTable = true;
  }

  protected onAbortAttendees(): void {
    this.visibleTheoryList = true;
    this.visibleStudentsTable = false;
    this.abortAttendeesUuid = new Date().valueOf();
    this.studentToMatch = null;
  }

  protected async onSubmitAttendees(): Promise<void> {
    if (!this.selectedTheoryLesson) {
      this.$toasted.info("Please select a course!");
      return;
    }

    await this.updateTheoryLesson({
      id: this.selectedTheoryLesson.id,
      data: {
        ...this.selectedTheoryLesson,
        studentTheoryLessons: this.theoryLessonStudentsMap(this.theoryLessonStudents),
      },
      resource: theoryLessonResource,
    });

    if (this.theoryLessonSuccess) {
      await this.fetchTheoryLessons(this.selectedRow);
    }
    this.studentToMatch = null;
  }

  public onClickTheoryLessonRow(theoryLesson: Array<any>): void {
    this.closePdfViewerOpenStudents();

    this.setTheoryLessonBookedStatus(theoryLesson[0]?.booked);

    if (!theoryLesson.length) {
      this.theoryDocumentId = null;
      this.resetSelectedTheoryLesson();
      return;
    }

    if (this.selectedTheoryLesson?.id !== theoryLesson[0].id) {
      this.oldSelectedTheoryLesson = this.selectedTheoryLesson;
    }

    this.selectedTheoryLesson = theoryLesson[0];
    this.theoryDocumentId = this.selectedTheoryLesson.theoryLessonDocumentId;

    if (this.oldSelectedTheoryLesson) {
      this.oldSelectedTheoryLesson._showDetails = false;
      this.prevSelectedTheoryLessonReset();
    }
    this.selectedTheoryLesson._showDetails = true;
  }

  protected async onStudentRowDblClick(item: any): Promise<void> {
    if (!this.selectedTheoryLesson?.id || !item?.id) return;

    const options = {
      theoryLessonId: this.selectedTheoryLesson.id,
      studentId: item.id,
    };

    let theoryIds = this.theoryLessonStudents.map((student: any) => {
      return student.id;
    });

    let hasStudent = _.includes(theoryIds, item.id);

    await this.findMatchByTheoryLessonAndStudentId(options);
    if (this.matchDataItem && !hasStudent) {
      this.theoryLessonStudents.push(item);
    } else if (this.matchDataItem && hasStudent) {
      return;
    } else if (!this.matchDataItem && hasStudent) {
      return;
    } else {
      this.studentToMatch = item;
      this.$bvModal.show("studentMatchModalPopover");
    }
  }

  protected onDeleteCourseStudent(item: any, index: number): void {
    this.theoryLessonStudents.splice(index, 1);
  }

  protected get instructorOptions(): Array<any> {
    return this.instructors.map((instructor: IInstructor) => {
      return this.instructorFormat(instructor);
    });
  }

  protected instructorFormat(instructor: IInstructor): any {
    if (!instructor) return null;
    return {
      id: instructor.id,
      name: formatInstructorName(instructor.firstName, instructor.lastName),
      initials: instructor.initials,
    };
  }

  protected get theoryCourseOptions(): Array<ITheoryClass> {
    if (!this.theory) return [];
    return this.theory.theoryClasses;
  }

  protected get theoryLessonsMap(): Array<any> {
    return this.theoryLessons.map((theoryCourse: any) => {
      const endTime = this.calcEndTime(theoryCourse.time, theoryCourse.duration);
      return {
        ...theoryCourse,
        instructor: this.instructorFormat(theoryCourse.instructor),
        endTime: endTime,
        defaultStartTime: theoryCourse.time,
        defaultEndTime: endTime,
      };
    });
  }

  protected calcEndTime(startTime: string, duration: number): string {
    const start = moment.utc(startTime, "HH:mm");
    return start.add(duration, "minutes").format("HH:mm");
  }

  protected async onSubmitTheoryLesson(item: any): Promise<void> {
    this.modalTable = "theory-session";
    this.submitTheorySessionData = item;
    const data = {
      ...item,
      duration: this.calcDuration(item.time, item.endTime),
    };

    if (data.instructor) {
      const startDateTime = this.toDateTime(data.date, data.time);
      const endDateTime = this.toDateTime(data.date, data.endTime);

      const checkData: ICheckOverlappingAppointmentsOptions = {
        instructorId: data.instructor.id,
        data: {
          eventId: item.id,
          eventTypeId: LESSON,
          startTime: startDateTime,
          endTime: endDateTime,
        },
      };

      if (!this.checkHoursModalConfirmed) {
        const checkWorkingHours = await this.checkInstructorWorkingHoursFn(checkData);
        if (checkWorkingHours === 1) {
          return;
        } else {
          this.checkHoursModalConfirmed = true;
        }
      }

      if (!this.checkOverlappingAppointmentModalConfirmed) {
        const checkOverlapping = await this.checkInstructorOverlappingAppointmentsFn(checkData);
        if (checkOverlapping === 1) {
          return;
        } else {
          this.checkOverlappingAppointmentModalConfirmed = true;
        }
      }
    }

    if (item.isNew) {
      Object.assign(data, {
        slots: this.theory?.slots,
        branch: this.theory?.branch,
        furtherEducation: this.theory?.furtherEducation,
      });
      await this.theoryLessonCreate({
        resource: theoryLessonResource,
        data: data,
      });
    } else {
      await this.updateTheoryLesson({
        id: item.id,
        data: data,
        resource: theoryLessonResource,
      });
    }
    if (this.theoryLessonSuccess) {
      item._showDetails = false;
      await this.fetchTheoryLessons(this.selectedRow);
    }

    this.submitTheorySessionData = null;
    this.resetConfirmationState();
  }

  protected onAbortTheoryLesson(item: any): void {
    item._showDetails = false;
    this.fetchTheoryLessons(this.selectedRow);
    this.$v.$reset();
  }

  protected onPrintAttendanceList(): void {
    if (!this.selectedTheoryLesson) return;

    getFile({
      method: "GET",
      url: `/theory-lesson-documents/attendance-list?theoryLessonId=${this.selectedTheoryLesson.id}`,
    });
  }

  protected theoryLessonStudentsMap(theoryLessonStudents: Array<any>): Array<any> {
    return theoryLessonStudents.map((student: any) => {
      return {
        studentId: student.id,
        studentFirstName: student.firstName,
        studentLastName: student.lastName,
      };
    });
  }

  protected theoryLessonStudentsDto(students: Array<any>): Array<any> {
    if (!students) return [];
    return students.map((student: any) => {
      return {
        id: student.studentId,
        firstName: student.studentFirstName,
        lastName: student.studentLastName,
        signed: student.signed,
        studentHasBirthDay: student.studentHasBirthday,
        activeEducations: student.studentEducations,
      };
    });
  }

  protected async fetchTheoryLessons(item: any): Promise<void> {
    if (item.theoryCourseId) {
      this.refreshCounter = this.refreshCounter + 1;
      const id = item.theoryCourseId;
      await this.theoryCourseFindOne({
        resource: theoryCourseResource,
        id: id,
      });

      await this.theoryLessonByCourseId(id);

      this.theoryLessons = this.theoryLessonCourses.map((item: any) => ({
        ...item,
        _showDetails: false,
      }));

      this.theory = this.theoryCourse;
    } else {
      await this.theoryLessonFindOne({
        resource: theoryLessonResource,
        id: item.theoryLessonId,
      });

      this.theoryLessons = [{ ...this.theoryLesson, _showDetails: false }];
      this.theory = this.theoryLesson;
    }
  }

  protected get selectedTheoryIsCourse(): boolean {
    return Boolean(this.selectedRow?.theoryCourseId);
  }

  protected get selectedTheoryIsLesson(): boolean {
    return Boolean(this.selectedRow?.theoryLessonId);
  }

  public onSignatureSignPopover(): void {
    if (!this.hasSignature(this.selectedTheoryLesson) && this.theoryDocumentId) {
      this.onSignaturePdfOverwritePopover();
    } else {
      this.$bvModal.show(this.signatureSignPopoverModal);
    }
  }

  protected hasSignature(item: any): boolean {
    return item.studentTheoryLessons.some(function (e: any) {
      return e.signed === true;
    });
  }

  public onSignaturePdfOverwritePopoverClose(): void {
    this.$bvModal.hide("signaturePdfOverwritePopover");
  }

  public onSignaturePdfOverwritePopoverSubmit(): void {
    this.$bvModal.show(this.signatureSignPopoverModal);
    this.$bvModal.hide("signaturePdfOverwritePopover");
  }

  public onSignaturePdfOverwritePopover(): void {
    this.$bvModal.show("signaturePdfOverwritePopover");
  }

  public setSelectedRowTheoryClass(selectedOptions: any) {
    this.selectedTheoryLesson.theoryClass = selectedOptions;
  }

  protected get getSelectedTheoryLessonTheoryClass() {
    if (this.selectedTheoryLesson?.theoryClass) {
      return this.selectedTheoryLesson.theoryClass;
    }
    return null;
  }

  @Watch("selectedRow")
  private setCourseId() {
    this.courseId = this.selectedRow.theoryCourseId;
  }

  private async prevSelectedTheoryLessonReset(): Promise<void> {
    if (this.selectedTheoryLesson) {
      const { id } = this.selectedTheoryLesson;
      this.theoryLessons = await this.theoryLessonCourses.map((item: any) => {
        return {
          ...item,
          _showDetails: item.id === id,
        };
      });
    }
  }

  private resetSelectedTheoryLesson(): void {
    this.oldSelectedTheoryLesson = null;
    this.selectedTheoryLesson = null;
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      theoryLessonsMap: {
        $each: {
          date: { required },
          time: { required },
          endTime: { required },
        },
      },
    };
  }

  protected onClickQRCode(): void {
    this.$router.push({ name: "StudentUploadQrcodeDocument" });
  }

  protected okWorkingHours(): void {
    this.checkHoursModalConfirmed = true;
    this.submitAgain();
  }

  protected okOverlappingAppointments(): void {
    this.checkOverlappingAppointmentModalConfirmed = true;
    this.submitAgain();
  }

  protected submitAgain(): void {
    if (this.modalTable == "theory-course") {
      this.onSubmitTheory(this.submitTheoryCourseData?.data, this.submitTheoryCourseData?.theory);
      return;
    }
    this.onSubmitTheoryLesson(this.submitTheorySessionData);
  }

  protected async onDeleteTheoryCourse(): Promise<void> {
    const { theoryCourseId, theoryLessonId } = this.selectedRow;
    if (this.selectedTheoryIsCourse) {
      await this.theoryCourseDelete({
        resource: theoryCourseResource,
        id: theoryCourseId,
      });
      if (this.theoryCourseIsDeleted) {
        this.fetchCoursesAndTheoryLessons();
        this.closeSessionsTable();
      }
    } else {
      await this.removeTheoryLesson(theoryLessonId);

      if (this.theoryLessonIsDeleted) {
        this.fetchCoursesAndTheoryLessons();
        this.closeSessionsTable();
      }
    }
  }

  protected async onDeleteTheoryLesson(): Promise<void> {
    await this.removeTheoryLesson(this.selectedTheoryLesson.id);
    if (this.theoryLessonIsDeleted) {
      this.fetchCoursesAndTheoryLessons();
      if (this.selectedTheoryIsCourse) {
        await this.fetchTheoryLessons(this.selectedRow);
      } else {
        this.closeSessionsTable();
      }
    }
  }

  public async removeTheoryLesson(id: number): Promise<void> {
    await this.theoryLessonRemove({
      resource: theoryLessonResource,
      id: id,
    });
  }

  protected showDeleteTheoryCourseModal(): void {
    if (this.selectedRow) this.showModal("delete-modal-course");
  }

  protected showDeleteTheoryLessonModal(): void {
    if (this.selectedRow && this.selectedTheoryLesson) this.showModal("delete-modal-lesson");
  }

  protected closeSessionsTable(): void {
    this.visibleSessionTable = false;
    this.visibleAttendees = false;
  }

  protected onClickSignature(item: any): void {
    this.visibleAttendees = false;
    this.visiblePdfView = true;
    getFile(
      {
        method: "GET",
        url: `/theory-lesson-documents/attendance-list?theoryLessonId=${item.id}`,
      },
      false
    ).then((blob: any) => {
      this.pdfFile = blob;
    });
  }

  protected closePdfViewerOpenStudents(): void {
    this.visiblePdfView = false;
    this.visibleAttendees = true;
    this.pdfFile = "";
  }

  protected onStudentRowClick(): void {
    this.closePdfViewerOpenStudents();
  }

  protected onMatchModalAccept(): void {
    if (this.studentToMatch) {
      this.theoryLessonStudents.push(this.studentToMatch);
    }
    this.$bvModal.hide("studentMatchModalPopover");
  }

  protected onMatchModalDecline(): void {
    this.studentToMatch = null;
    this.$bvModal.hide("studentMatchModalPopover");
  }

  @Provide("fetchTheoryLesson")
  protected async fetchTheoryLessonProvider(): Promise<void> {
    await this.fetchTheoryLessons(this.selectedRow);
  }

  public get isLoading() {
    return this.theoryLessonsIsLoading || this.theoryIsLoading || this.theoryCourseLoading || this.theoryLessonLoading;
  }

  public onEmailFormShow() {
    this.selectedStudents = this.selectedTheoryLesson.studentTheoryLessons?.map((lesson: any) => {
      return {
        email: lesson.email ? lesson.email : "",
        firstName: lesson.studentFirstName,
        lastName: lesson.studentLastName,
      };
    });
    this.visibleSessionTable = false;
    this.visibleEmailForm = true;
  }

  public onEmailFormClose() {
    this.visibleSessionTable = true;
    this.visibleEmailForm = false;
  }

  public onShowModuleBookingModal() {
    if (this.selectedRow && this.theory) {
      this.showModuleBookingModal = true;
    }
  }

  public onModuleBookingModalClose() {
    this.showModuleBookingModal = false;
  }

  public async onOpenMessage() {
    if (!this.theory) return;
    const theoryCourseId = this.theory.id;
    await this.$router.push({ name: "Broadcast", query: { newForm: "true" } });
    this.$root.$emit("broadcast-selection-change", { formIsNew: true, entryId: theoryCourseId, type: "theoryCourse" });
  }
}
